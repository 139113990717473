.relatedPostSectionHeader {
  margin-top: 4rem;
  margin-bottom: 1.5rem;
}

.relatedPostsList {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.relatedPost {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.relatedPost_title {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  background-repeat: no-repeat;
}

.relatedPost_title:has(.relatedPost_link) {
  padding-right: 0.75rem;
  background-image: url('./arrowLink.svg');
  background-position: right top;
}

.relatedPost_link::before {
  content: '';
  position: absolute;
  z-index: 0;
  inset: 0;
}

.relatedPost_description {
  font-size: 1rem;
  line-height: 1.6;
}

.relatedPost_info {
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #888a98;
}
