ul.toc {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

ul.toc li > a {
  display: flex;
  align-items: baseline;
  margin: 0;
  padding-left: 0;
  font-size: 1rem;
  line-height: 1.4rem;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  text-decoration-line: none;
  transition-duration: 100ms;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  --tw-text-opacity: 0.8;
}

ul.toc li > a strong,
ul.toc li > a em,
ul.toc li > a i,
ul.toc li > a b {
  font-weight: 400;
  font-style: normal;
}

ul.toc li > a:hover {
  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));
  text-decoration-line: none;
}

ul.toc li > a[aria-selected='true'] {
  --tw-text-opacity: 1;

  font-weight: 500;
}

ul.toc li > a::before {
  content: '';
  transform: translateY(-0.15rem);
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.5rem;
  margin-left: -1rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 9999px;
  opacity: 0;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  --tw-bg-opacity: 1;
}

ul.toc li > a[aria-selected='true']::before {
  opacity: 1;
}

ul.toc li[data-level='h3'] > a::before {
  margin-right: 1.25rem;
}

ul.toc li[data-level='h4'] > a::before {
  margin-right: 2rem;
}
