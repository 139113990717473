.wrapper {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  font-size: inherit;
  color: inherit;
}

.wrapper:not(.wrapper__textOnly) {
  position: relative;
  padding-right: 1.5rem;
}

.wrapper:not(.wrapper__textOnly):has(+ *)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #c6cef1;
}

.calendarIcon {
  margin-top: 0;
  margin-bottom: 0;
  stroke: currentcolor;
}
