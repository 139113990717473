
      @import 'styles-scss/abstracts/mixins';
    
.section {
  padding: calc(var(--space-md) * 2) 0;

  @include break-reverse(768) {
    padding: calc(var(--space-md) * 3) 0;
  }
}

.wrapper {
  position: relative;
  border: 1px solid var(--primary-40);
  border-radius: 12px;
  background-color: var(--primary-100);
  box-shadow: 0 0 15px 0 rgba(244, 245, 246, 0.1) inset;
  overflow: hidden;
  isolation: isolate;

  @include break(767) {
    padding: var(--space-xl) var(--space-sm);
  }
}

.heading {
  position: relative;
  z-index: 5;
  text-align: left;
  margin-bottom: var(--space-lg);

  @include break-reverse(768) {
    text-align: center;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    margin-bottom: 0;
    width: 95%;
  }
}

.title {
  margin-bottom: var(--space-md);
  text-align: center;
  font-size: var(--header-xs-24);

  @include break-reverse(768) {
    margin-bottom: var(--space);
    font-size: var(--header-md-32);
  }

  @include break-reverse(992) {
    margin-bottom: var(--space-lg);
    font-size: var(--header-xl-40);
  }

  @include break-reverse(1240) {
    font-size: var(--header-xxl-48);
  }
}

.button {
  margin: 0 auto;
  text-align: center;
}

.image {
  position: relative;
  z-index: 0;
  display: block;

  @include break(767) {
    margin: 0 auto;
    max-width: 322px;
  }
}

.glow {
  position: absolute;
  z-index: 0;
  background: radial-gradient(
    1584.62% 778.69% at 143.26% -670%,
    var(--neutral-0) 0%,
    var(--primary-100) 48%,
    var(--neutral-55) 100%
  );
  bottom: -30%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 250%;
  height: 50%;
  border-radius: 100%;
  filter: blur(70px);
  opacity: 0.6;

  @include break-reverse(768) {
    display: none;
  }
}
