.backdrop {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));

  --tw-bg-opacity: 0.95;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.zoomLink:has(figure > img) {
  position: relative;
}

.zoomLink:has(figure > img)::before {
  content: '';
  position: absolute;
  z-index: 20;
  top: 1rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("./zoom-icon.svg");
  background-size: contain;
}

.zoomLink:has(figure > img)::after {
  content: '';
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: 3.5rem;
  height: 3.5rem;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  border-bottom-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;

  --tw-bg-opacity: 0.05;
}

.zoomLink:has(figure > img):hover::after {
  --tw-bg-opacity: 0.2;
}
