.accordion-block__items {
  border-top: 1px solid rgb(from var(--neutral-40) r g b / 0.6);
}

.accordion-block__item {
  padding: var(--space-sm) 0;
  border-bottom: 1px solid rgb(from var(--neutral-40) r g b / 0.6);

  @include break-reverse(768) {
    padding: var(--space) 0;
  }

  @include break-reverse(992) {
    padding: var(--space-md) 0;
  }
}

.accordion-block__item-title {
  position: relative;
  display: inline-block;
  padding: 0 var(--space-lg) 0 0;
  width: 100%;
  font-family: var(--font-satoshi);
  font-size: var(--text-md);
  font-weight: 700;
  line-height: 1.35;
  cursor: pointer;

  @include break-reverse(768) {
    padding: 0 var(--space-xl) 0 0;
    font-size: var(--text-lg);
  }

  @include break-reverse(992) {
    padding: 0 var(--space-xxl) 0 0;
  }
}

.accordion-block__item-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(0, -50%, 0);
  width: var(--space);
  height: var(--space);
  stroke: var(--neutral-0);
  transition: all var(--transition);

  @include break-reverse(768) {
    width: var(--space-md);
    height: var(--space-md);
  }
}

.accordion-block__item-checkbox {
  display: none;

  &:checked {
    ~ .accordion-block__item-title {
      .accordion-block__item-icon {
        transform: rotate(180deg);
      }
    }

    ~ .accordion-block__item-content {
      padding: var(--space-sm) 0 0;
      height: auto;
      transition: all var(--transition);

      @include break-reverse(768) {
        padding: var(--space) 0 0;
      }

      @include break-reverse(992) {
        padding: var(--space-md) 0 0;
      }
    }
  }
}

.accordion-block__item-content {
  height: 0;
  font-family: var(--font-satoshi);
  font-size: var(--text-md);
  font-weight: 400;
  line-height: 1.35;
  transition: all var(--transition);
  overflow: hidden;

  @include break-reverse(768) {
    font-size: var(--text-lg);
  }
}
