
      @import 'styles-scss/abstracts/mixins';
    
.styledTypography h1 {
}

.styledTypography h2 {
  margin-top: 3.5rem;
  margin-bottom: 2rem;
}

.styledTypography h3,
.styledTypography h4 {
  margin-top: 2rem;
  margin-bottom: -0.5rem;
  font-size: 1.25rem; /* 20px */
  font-weight: 500;
  line-height: 1.6; /* 32px */
}

.styledTypography h2 + h3,
.styledTypography h2 + h4 {
  margin-top: 2rem;
}

.styledTypography h5 {
  margin-top: 2rem;
  margin-bottom: -0.75rem;
  font-weight: 500;
}

.styledTypography h2 + h6 {
  margin-top: -1.75rem;
  margin-bottom: 2rem;
  font-size: 1.25rem; /* 20px */
}

.styledTypography p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.styledTypography p code {
  margin-right: 0.125rem;
  margin-left: 0.125rem;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
  background-color: rgb(74, 142, 242, var(--tw-bg-opacity));

  --tw-bg-opacity: 0.15;
}

.styledTypography h1 a,
.styledTypography h2 a,
.styledTypography h3 a,
.styledTypography h4 a,
.styledTypography h5 a,
.styledTypography h6 a,
.styledTypography p a,
.styledTypography li a {
  --tw-text-opacity: 1;

  color: rgb(74, 142, 242, var(--tw-text-opacity));
}

.styledTypography h1 a:hover,
.styledTypography h2 a:hover,
.styledTypography h3 a:hover,
.styledTypography h4 a:hover,
.styledTypography h5 a:hover,
.styledTypography h6 a:hover,
.styledTypography p a:hover,
.styledTypography li a:hover {
  text-decoration-line: underline;
}

.styledTypography a:has(figure figcaption) {
  text-decoration-line: none;
}

.styledTypography img {
  margin-top: 2rem;
  margin-bottom: 3rem;
  border-radius: 1.5rem;
}

.styledTypography figure {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.styledTypography figure img {
  margin-top: 0;
  margin-bottom: 0;
}

.styledTypography figure figcaption {
  margin-top: 1rem;
  opacity: 0.6;
}

.styledTypography ul {
  margin-top: 1.25rem;
  margin-bottom: 2rem;
}

.styledTypography ul li {
  position: relative;
  padding-left: 2rem;
}

.styledTypography ul li:not(:last-child) {
  margin-bottom: 0.75rem;
}

.styledTypography ul li::before {
  content: '';
  position: absolute;
  top: 0.85rem;
  left: 0.4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  margin-top: -3px;
  width: 9px;
  height: 9px;
  border-radius: 2px;
  background-color: rgb(255, 255, 255, var(--tw-bg-opacity));
  transform-origin: center;

  --tw-bg-opacity: 1;
  --tw-rotate: 45deg;
}

.styledTypography ol {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  list-style: auto;
}

.styledTypography ol li {
  margin-left: 1rem;
}

.styledTypography ol li:not(:last-child) {
  margin-bottom: 0.75rem;
}

.styledTypography li,
.styledTypography p,
.styledTypography table {
  line-height: 1.6;
  letter-spacing: 0.009em;
  color: rgb(255, 255, 255, var(--tw-text-opacity));

  --tw-text-opacity: 0.9;
}

@media (min-device-pixel-ratio: 2), (resolution >= 192dpi) {
  .styledTypography li,
  .styledTypography p,
  .styledTypography table {
    letter-spacing: initial;
  }
}

.styledTypography div:global(.Code) {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.styledTypography div:global(.Code) pre:global([class*='language-']) {
  --tw-border-opacity: 1;

  border-color: rgb(15, 33, 67, var(--tw-border-opacity));
}

.styledTypography :global(.table-wrapper) {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  border-width: 1px;
  border-color: rgb(15, 33, 67, var(--tw-border-opacity));
  border-radius: 0.5rem;
  overflow-x: auto;
  background-color: #0f1737;

  --tw-border-opacity: 1;
}

.styledTypography table {
  border-collapse: separate;
  border-spacing: 0;
}

.styledTypography table thead th,
.styledTypography table tbody th,
.styledTypography table thead td,
.styledTypography table tbody td {
  vertical-align: top;
  padding: 1.25rem 1.5rem;
  border-width: 1px;
  border-color: rgb(15, 33, 67, var(--tw-border-opacity));

  --tw-border-opacity: 1;
}

.styledTypography table thead th:last-child,
.styledTypography table tbody th:last-child,
.styledTypography table thead td:last-child,
.styledTypography table tbody td:last-child {
  border-right-width: 0;
}

.styledTypography table thead th img,
.styledTypography table tbody th img,
.styledTypography table thead td img,
.styledTypography table tbody td img {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.styledTypography table thead th p:first-child,
.styledTypography table tbody th p:first-child,
.styledTypography table thead td p:first-child,
.styledTypography table tbody td p:first-child {
  margin-top: 0;
}

.styledTypography table thead th p:last-child,
.styledTypography table tbody th p:last-child,
.styledTypography table thead td p:last-child,
.styledTypography table tbody td p:last-child {
  margin-bottom: 0;
}

.styledTypography table thead th {
  text-align: left;
  font-weight: 500;
  border-bottom: none;
}

.styledTypography table tbody th {
  text-align: left;
  font-weight: 500;
}

.styledTypography table tbody tr:not(:last-child) td {
  border-bottom: none;
}
