.quoteText {
  margin-top: 2rem;
  margin-bottom: 3rem;
  border-width: 1px;
  border-color: #283469;
  border-radius: 0.75rem;
  overflow: hidden;
  background-color: #1a1b3b;
}

.titleWrapper {
  padding: 0.5rem 1.5rem;
  background-color: #283469;
}

.titleWrapper .title {
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.titleWrapper > h2,
.titleWrapper h3,
.titleWrapper h4,
.titleWrapper h5 {
  margin: 0;
  font-size: 1.125rem;
}

.quoteBody {
  padding: 1rem 1.5rem;
}

.quoteBody > h2,
.quoteBody h3,
.quoteBody h4,
.quoteBody h5 {
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
}

.quoteBody > h2:first-child,
.quoteBody h3:first-child,
.quoteBody h4:first-child,
.quoteBody h5:first-child {
  margin-top: 1.25rem;
}
