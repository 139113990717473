/* ----- alice carousel ----- */
.alice-carousel {
  .alice-carousel__stage {
    display: flex;
  }

  .alice-carousel__stage-item {
    flex: 1 0 auto;
    height: auto;
  }

  .alice-carousel__dots {
    position: relative;
    z-index: 10;
  }
}
