.content {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.main {
  width: 100%;
}

@media (width >= 768px) {
  .main {
    flex-basis: 77%;
  }
}

.main .main_content {
  max-width: 48.5rem;
}

.sidebar {
  display: none;
  flex-basis: 21%;
}

@media (width >= 768px) {
  .sidebar {
    display: block;
  }

  .sidebar_content__sticky {
    position: sticky;
    top: 2rem;
    bottom: 1rem;
  }
}
