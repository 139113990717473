@layer reset, website, variables, blocks, typography;

@layer reset {
  /*
  1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
  2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
  */

  *,
  ::before,
  ::after {
    margin: 0;
    padding: 0;
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: currentcolor; /* 2 */
    box-sizing: border-box; /* 1 */
  }

  ::before,
  ::after {
    --tw-content: '';
  }

  /*
  1. Use a consistent sensible line-height in all browsers.
  2. Prevent adjustments of font size after orientation changes in iOS.
  3. Use a more readable tab size.
  4. Use the user's configured `sans` font-family by default.
  5. Use the user's configured `sans` font-feature-settings by default.
  6. Use the user's configured `sans` font-variation-settings by default.
  7. Disable tap highlights on iOS
  */

  html,
  :host {
    font-family: ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; /* 4 */

    line-height: 1.5; /* 1 */
    font-feature-settings: normal; /* 5 */
    font-variation-settings: normal; /* 6 */
    tab-size: 4; /* 3 */
    -webkit-tap-highlight-color: transparent; /* 7 */
    text-size-adjust: 100%; /* 2 */ /* 3 */
  }

  /*
  1. Remove the margin in all browsers.
  2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
  */

  body {
    margin: 0; /* 1 */
    line-height: inherit; /* 2 */
  }

  /*
  1. Add the correct height in Firefox.
  2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
  3. Ensure horizontal rules are visible by default.
  */

  hr {
    height: 0; /* 1 */
    color: inherit; /* 2 */
    border-top-width: 1px; /* 3 */
  }

  /*
  Add the correct text decoration in Chrome, Edge, and Safari.
  */

  abbr:where([title]) {
    text-decoration: underline dotted;
  }

  /*
  Remove the default font size and weight for headings.
  */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  /*
  Reset links to optimize for opt-in styling instead of opt-out.
  */

  a {
    text-decoration: inherit;
    color: inherit;
  }

  /*
  Add the correct font weight in Edge and Safari.
  */

  b,
  strong {
    font-weight: bolder;
  }

  /*
  1. Use the user's configured `mono` font-family by default.
  2. Use the user's configured `mono` font-feature-settings by default.
  3. Use the user's configured `mono` font-variation-settings by default.
  4. Correct the odd `em` font sizing in all browsers.
  */

  code,
  kbd,
  samp,
  pre {
    font-family: 'Fira Code', Monaco, Consolas, 'Lucida Console', 'Andale Mono',
      monospace; /* 1 */

    font-size: 1em; /* 4 */
    font-feature-settings: normal; /* 2 */
    font-variation-settings: normal; /* 3 */
  }

  /*
  Add the correct font size in all browsers.
  */

  small {
    font-size: 80%;
  }

  /*
  Prevent `sub` and `sup` elements from affecting the line height in all browsers.
  */

  sub,
  sup {
    position: relative;
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  /*
  1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
  2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
  3. Remove gaps between table borders by default.
  */

  table {
    border-color: inherit; /* 2 */
    border-collapse: collapse; /* 3 */
    text-indent: 0; /* 1 */
  }

  /*
  1. Change the font styles in all browsers.
  2. Remove the margin in Firefox and Safari.
  3. Remove default padding in all browsers.
  */

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0; /* 2 */
    padding: 0; /* 3 */
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    font-weight: inherit; /* 1 */
    line-height: inherit; /* 1 */
    letter-spacing: inherit; /* 1 */
    color: inherit; /* 1 */
    font-feature-settings: inherit; /* 1 */
    font-variation-settings: inherit; /* 1 */
  }

  /*
  Remove the inheritance of text transform in Edge and Firefox.
  */

  button,
  select {
    text-transform: none;
  }

  /*
  1. Correct the inability to style clickable types in iOS and Safari.
  2. Remove default button styles.
  */

  button,
  input:where([type='button']),
  input:where([type='reset']),
  input:where([type='submit']) {
    background-image: none; /* 2 */
    appearance: button; /* 1 */
    background-color: transparent; /* 2 */
  }

  /*
  Use the modern Firefox focus style for all focusable elements.
  */

  :-moz-focusring {
    outline: auto;
  }

  /*
  Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
  */

  :-moz-ui-invalid {
    box-shadow: none;
  }

  /*
  Add the correct vertical alignment in Chrome and Firefox.
  */

  progress {
    vertical-align: baseline;
  }

  /*
  Correct the cursor style of increment and decrement buttons in Safari.
  */

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  /*
  1. Correct the odd appearance in Chrome and Safari.
  2. Correct the outline style in Safari.
  */

  [type='search'] {
    outline-offset: -2px; /* 2 */
    appearance: textfield; /* 1 */
  }

  /*
  Remove the inner padding in Chrome and Safari on macOS.
  */

  ::-webkit-search-decoration {
    appearance: none;
  }

  /*
  1. Correct the inability to style clickable types in iOS and Safari.
  2. Change font properties to `inherit` in Safari.
  */

  ::-webkit-file-upload-button {
    font: inherit; /* 2 */
    appearance: button; /* 1 */
  }

  /*
  Add the correct display in Chrome and Safari.
  */

  summary {
    display: list-item;
  }

  /*
  Removes the default spacing and border for appropriate elements.
  */

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  legend {
    padding: 0;
  }

  ol,
  ul,
  menu {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  /*
  Reset default styling for dialogs.
  */

  dialog {
    padding: 0;
  }

  /*
  Prevent resizing textareas horizontally by default.
  */

  textarea {
    resize: vertical;
  }

  /*
  1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
  2. Set the default placeholder color to the user's configured gray 400 color.
  */

  input::placeholder,
  textarea::placeholder {
    color: #9ca3af; /* 2 */
    opacity: 1; /* 1 */
  }

  /*
  Set the default cursor for buttons.
  */

  button,
  [role='button'] {
    cursor: pointer;
  }

  /*
  Make sure disabled buttons don't get the pointer cursor.
  */

  :disabled {
    cursor: default;
  }

  /*
  1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
  2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
     This can trigger a poorly considered lint error in some tools but is included by design.
  */

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block; /* 1 */
    vertical-align: middle; /* 2 */
  }

  /*
  Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
  */

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  /* Make elements with the HTML hidden attribute stay hidden by default */

  [hidden] {
    display: none;
  }

  /* ----- scroll ----- */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--primary-60) transparent;
  }
}

@layer website {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  hr {
    margin: initial;
    padding: initial;
  }

  hr {
    border: initial;
  }

  :root {
    --website-dark-bg: #080a1e;
  }

  /**
   * Basic REM size
   */

  html {
    font-size: 14px;
    font-weight: 400;
    scroll-behavior: smooth;
  }

  @media (width >= 1024px) {
    html {
      font-size: 15px;
    }
  }

  @media (width >= 1280px) {
    html {
      font-size: 16px;
    }
  }

  /**
   * Set specific styles
   */

  body {
    max-width: 100%;
    font-family: var(--font-satoshi);
    font-size: 1.125rem;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    background-color: var(--website-dark-bg);
    -webkit-font-smoothing: antialiased;

    --tw-text-opacity: 1;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-clash);
  }

  h1 {
    padding: 0;
  }

  h1 {
    text-wrap: balance;
    font-size: 2.375rem; /* 38rem */
    line-height: 3.429rem;
  }

  @media (width >= 768px) {
    h1 {
      font-size: 3rem; /* 48px */
    }
  }

  /* 48px */

  h2 {
    padding: 0;
    font-size: 2rem;
    line-height: 1.2; /* 32px */
  }

  @media (width >= 768px) {
    h2 {
      font-size: 2.375rem; /* 38px */
    }
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    line-height: 1.55; /* 24px */
  }

  @media (width >= 768px) {
    h3 {
      font-size: 2rem; /* 32px */
    }
  }

  /* 46px */

  h4,
  .likeH4 {
    margin: 0;
    padding: 0;
    font-size: 1.25rem; /* 20px */
    line-height: 1.6; /* 32px */
  }

  h5 {
    font-size: 1.125rem;
    line-height: 1.445; /* 26px */
  }

  p {
    margin: 0;
    padding: 0;
    line-height: 1.6;
    color: rgb(255 255 255 / var(--tw-text-opacity));

    --tw-text-opacity: 0.8;
  }

  a {
    cursor: pointer;
    text-decoration-line: none;
  }

  a:hover {
    text-decoration-line: underline;
  }

  a {
    transition-duration: 150ms;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}
