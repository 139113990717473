
      @import 'styles-scss/abstracts/mixins';
    
/* Button */

.button {
  &:hover {
    .button_icon {
      stroke: var(--neutral-100);
    }
  }

  > span {
    gap: var(--space-xs);
  }
}

.button_caption__reversed {
  @include break-reverse(768) {
    order: 1;
  }
}

.button_icon {
  flex-shrink: 0;
  transform: translate(0, -1px);
  width: 20px;
  height: 20px;
  stroke: var(--neutral-0);
  stroke-width: 1.5px;
  transition: all var(--transition);
}

.button_icon__reversed {
  @include break-reverse(768) {
    order: 0;
  }
}
