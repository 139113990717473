.heroImage {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 100%;
  border-radius: 1rem;
  overflow: hidden;
}

@media (width >= 768px) {
  .heroImage {
    margin-bottom: 1.75rem;
  }
}
