
      @import 'styles-scss/abstracts/mixins';
    
.wrapper {
  position: relative;

  &:has(.input:hover, .input:focus) {
    .icon {
      stroke: var(--neutral-30);
    }
  }
}

.wrapper__icon {
  .input {
    padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-lg);
  }
}

.input {
  padding: var(--space-xs) var(--space-sm);
  width: 100%;
  text-overflow: ellipsis;
  font-size: var(--text-md);
  font-weight: 400;
  color: var(--neutral-0);
  background-color: transparent;
  border: 1px solid var(--neutral-60);
  border-radius: 30px;
  outline: none;
  transition: all var(--transition);

  &[type='search'] {
    &::-webkit-search-cancel-button {
      appearance: none;
    }
  }

  &:hover,
  &:focus {
    border-color: var(--neutral-40);
  }

  &::placeholder {
    color: var(--neutral-65);
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(16px, -50%);
  width: 16px;
  height: 16px;
  stroke: var(--neutral-60);
  stroke-width: 1.2;
  transition: all var(--transition);

  @include break-reverse(768) {
    width: 20px;
    height: 20px;
  }
}
