/* ----- common styles----- */
.btn,
.link {
  position: relative;

  &,
  &::before,
  span,
  .icon,
  svg {
    transition: all var(--transition);
  }
}

.btn,
.link--primary,
.link--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-xs) var(--space-sm);
  font-family: var(--font-clash);
  font-size: var(--text-lg);
  font-weight: 400;
  line-height: 1.45;
  color: var(--neutral-0);
  background: transparent;
  border: none;
  border-radius: 31px;
  cursor: pointer;

  @include break-reverse(768) {
    padding: var(--space-xs) var(--space);
  }
}

.btn--primary,
.link--primary,
.btn--secondary,
.link--secondary {
  &::before {
    content: '';
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(var(--neutral-0) 0%, var(--neutral-37) 100%);
    border-radius: inherit;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &:hover {
    span {
      color: var(--neutral-100);
    }
  }
}

.btn--primary,
.link--primary {
  background: linear-gradient(
    95deg,
    var(--primary-10) 0%,
    var(--primary-30) 100%
  );
}

.btn--secondary,
.link--secondary {
  background: transparent;
  box-shadow: inset 0px 0px 0px 1px var(--neutral-50);
}

.btn__content,
.link__content {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
}

/* ----- links----- */
.link {
  &[target='_blank'] {
    &:after {
      content: none !important;
    }
  }
}

.link--line,
.link--no-line,
.link--line-gradient,
.link--no-line-gradient {
  display: inline-block;

  .link__content {
    display: inline;
  }
}

.link--line {
  .link__content {
    background: linear-gradient(var(--neutral-0), var(--neutral-0)) 0% 100%/100%
      1px no-repeat;
  }

  &:hover {
    .link__content {
      background-size: 100% 0px;
    }
  }
}

.link--no-line {
  .link__content {
    background: linear-gradient(var(--neutral-0), var(--neutral-0)) 0% 100%/100%
      0px no-repeat;
  }

  &:hover {
    .link__content {
      background-size: 100% 1px;
    }
  }
}

.link--line-gradient,
.link--no-line-gradient {
  .link__content {
    color: transparent;
  }
}

.link--line-gradient {
  .link__content {
    background:
      linear-gradient(90deg, var(--primary-15) 0%, var(--primary-25) 100%),
      linear-gradient(90deg, var(--primary-15) 0%, var(--primary-25) 100%) 0%
        100%/100% 1px no-repeat;
    background-clip: text, padding-box;
  }

  &:hover {
    .link__content {
      background-size:
        100%,
        100% 0px;
    }
  }
}

.link--no-line-gradient {
  .link__content {
    background:
      linear-gradient(90deg, var(--primary-15) 0%, var(--primary-25) 100%),
      linear-gradient(90deg, var(--primary-15) 0%, var(--primary-25) 100%) 0%
        100%/100% 0px no-repeat;
    background-clip: text, padding-box;
  }

  &:hover {
    .link__content {
      background-size:
        100%,
        100% 1px;
    }
  }
}
