.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  width: 100%;
  border-radius: 9999px;
  background-color: #283469;
}

@media (width >= 768px) {
  .wrapper {
    width: 50%;
  }
}

ul.shareList {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.75rem;
  margin: 0;
}

ul.shareList li {
  padding: 0;
}

ul.shareList li::before {
  content: none;
}

ul.shareList li:not(:last-child) {
  margin: 0;
}

ul.shareList li img {
  margin-top: 0;
  margin-bottom: 0;
}
