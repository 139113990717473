/* ----- base styles ----- */

#__next {
  display: contents;
}

body {
  word-break: normal;
  overflow-x: hidden;
  text-size-adjust: none;

  * {
    color: var(--neutral-0);
  }
}

a {
  display: inline-flex;
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

img,
svg {
  display: inline-flex;
  max-width: 100%;
  width: auto;
}

/* ----- error boundaries ----- */
.global-error-boundary {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
}
