@layer variables {
  :root {
    /* ----- constants ----- */
    --size-point: 8px;

    /* ----- base colors ----- */
    --primary-100: rgb(8, 10, 30);
    --primary-95: rgb(10, 14, 36);
    --primary-90: rgb(11, 13, 33);
    --primary-85: rgb(13, 15, 35);
    --primary-83: rgb(12, 15, 37);
    --primary-80: rgb(11, 16, 40);
    --primary-77: rgb(14, 19, 45);
    --primary-75: rgb(17, 24, 60);
    --primary-70: rgb(16, 21, 47);
    --primary-65: rgb(30, 35, 65);
    --primary-60: rgb(26, 31, 57);
    --primary-55: rgb(52, 57, 88);
    --primary-50: rgb(28, 46, 74);
    --primary-45: rgb(48, 41, 104);
    --primary-40: rgb(51, 56, 82);
    --primary-35: rgb(49, 81, 135);
    --primary-30: rgb(108, 87, 242);
    --primary-25: rgb(85, 115, 167);
    --primary-20: rgb(76, 129, 208);
    --primary-15: rgb(108, 151, 236);
    --primary-13: rgb(124, 68, 235);
    --primary-10: rgb(147, 132, 249);
    --primary-0: rgb(202, 222, 251);

    --neutral-100: rgb(15, 28, 38);
    --neutral-90: rgb(15, 33, 67);
    --neutral-80: rgb(71, 86, 115);
    --neutral-75: rgb(112, 116, 139);
    --neutral-70: rgb(108, 112, 142);
    --neutral-65: rgb(100, 105, 131);
    --neutral-60: rgb(124, 129, 155);
    --neutral-55: rgb(150, 183, 232);
    --neutral-50: rgb(150, 155, 181);
    --neutral-40: rgb(174, 179, 205);
    --neutral-37: rgb(187, 192, 199);
    --neutral-35: rgb(199, 204, 230);
    --neutral-30: rgb(224, 229, 255);
    --neutral-25: rgb(244, 245, 246);
    --neutral-20: rgb(217, 217, 217);
    --neutral-15: rgb(231, 233, 245);
    --neutral-10: rgb(239, 241, 255);
    --neutral-5: rgb(247, 249, 255);
    --neutral-3: rgb(255, 253, 255);
    --neutral-0: rgb(255, 255, 255);

    --additional-purple: rgb(189, 104, 255);
    --additional-magenta: rgb(54, 17, 60);
    --additional-green: rgb(171, 245, 192);
    --additional-green-5: rgb(143, 234, 188);
    --additional-green-10: rgb(153, 242, 183);
    --additional-red: rgb(255, 143, 117);
    --additional-red-10: rgb(255, 122, 89);
    --additional-tomato: rgb(244, 58, 58);
    --additional-yellow: rgb(255, 222, 117);

    /* ----- font-families ----- */
    --satoshi: var(--font-satoshi);
    --clash-grotesk: var(--font-clash);
    --logotype: var(--font-logo);
    --fira: var(--font-fira);
    --digital-numbers: var(--font-digital);

    /* ----- font-sizes ----- */
    --header-xxs-20: 20px;
    --header-xs-24: 24px;
    --header-sm-28: 28px;
    --header-md-32: 32px;
    --header-lg-36: 36px;
    --header-xl-40: 40px;
    --header-xxl-48: 48px;
    --header-main-s: 54px;
    --header-main-m: 64px;

    --text-xs: 12px;
    --text-sm: 14px;
    --text-md: 16px;
    --text-lg: 18px;

    /* ----- spaces ----- */
    --space-xxs: calc(var(--size-point) * 0.5); /* 4px */
    --space-xs: var(--size-point); /* 8px */
    --space-sm: calc(var(--size-point) * 2); /* 16px */
    --space: calc(var(--size-point) * 3); /* 24px */
    --space-md: calc(var(--size-point) * 4); /* 32px */
    --space-lg: calc(var(--size-point) * 5); /* 40px */
    --space-xl: calc(var(--size-point) * 6); /* 48px */
    --space-xxl: calc(var(--size-point) * 7); /* 56px */

    /* ----- transition ----- */
    --transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}
