.hgroup {
  margin-right: auto;
  margin-left: auto;
  max-width: 48rem;
}

.hgroup h1,
.hgroup h2,
.hgroup h3 {
  text-wrap: balance;
}

.hgroup p {
  margin-top: 1rem;
  text-wrap: balance;
}

@media (width >= 768px) {
  .hgroup p {
    margin-right: 4rem;
    margin-left: 4rem;
  }
}

.hgroup p a {
  --tw-text-opacity: 1;

  color: rgb(74 142 242 / var(--tw-text-opacity));
}

.hgroup p a:hover {
  text-decoration-line: underline;
}

@media (width >= 640px) {
  .hgroup__left {
    text-align: left;
  }

  .hgroup__right {
    text-align: right;
  }

  .hgroup__center {
    text-align: center;
  }
}
