.articleMetaData {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  font-size: 0.875rem;
  color: rgb(255 255 255 / var(--tw-text-opacity));

  --tw-text-opacity: 0.8;
}
