.content {
  max-width: 55rem;
}

.content h1,
.content h2,
.content h3,
.content h4 {
  line-height: 1.3;
}

.content h2 {
  margin-top: 4rem;
}

.content h3 {
  margin-top: 2.5rem;
}

.content h4 {
  margin-top: 1.5rem;
}

.content p {
  margin-top: 1rem;
}

.content ul {
  margin-top: 0.5rem;
  padding-left: 2rem;
  list-style-type: disc;
}
