@use 'sass:meta';

/* ----- media queries ----- */
@mixin break($px) {
  @media (max-width: "#{$px}px") {
    @content;
  }
}

@mixin break-reverse($px) {
  @media (min-width: "#{$px}px") {
    @content;
  }
}

@mixin break-and-break-reverse($w1px, $w2px) {
  @media (min-width: "#{$w1px}px") and (max-width: "#{$w2px}px") {
    @content;
  }
}

@mixin bg-image {
  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin gradient-border($border-radius: 14px, $angle: 152.67deg) {
  --mixin-gradient-border-border-radius: #{$border-radius};
  --mixin-gradient-border-angle: #{$angle};
  --mixin-gradient-border-colors: #{var(--primary-75)} -5.94%,
    #{var(--neutral-15)} 55.4%, #{var(--primary-55)} 82.96%;

  position: relative;
  border-radius: var(--mixin-gradient-border-border-radius);

  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    inset: 0;
    background: linear-gradient(
        var(--mixin-gradient-border-angle),
        var(--mixin-gradient-border-colors)
      )
      border-box;
    mask:
      linear-gradient(var(--neutral-0) 0 0) padding-box,
      linear-gradient(var(--neutral-0) 0 0);
    mask-composite: exclude;
    border: 1px solid transparent;
    border-radius: var(--mixin-gradient-border-border-radius);
  }
}

@mixin gradient-border-angle($angle) {
  --mixin-gradient-border-angle: #{$angle};
}

@mixin gradient-border-border-radius($border-radius) {
  --mixin-gradient-border-border-radius: #{$border-radius};
}

@mixin gradient-border-colors($colors...) {
  --mixin-gradient-border-colors: #{$colors};
}

@mixin section-title($fz: var(--header-xs-24), $lh: 1.33, $fw: 500) {
  font-size: $fz;
  font-family: var(--font-clash);
  font-weight: $fw;
  line-height: $lh;
}

@mixin bg-dotted-pattern($mask: true, $circle-opacity: 0.2) {
  transform: translate3d(-50%, 0, 0);
  background-image: radial-gradient(
    circle,
    rgb(from var(--primary-20) r g b / $circle-opacity) 2px,
    transparent 1px
  );
  background-size:
    26px 26px,
    auto;
  background-position: center;

  @if $mask {
    mask-image: radial-gradient(
      farthest-side,
      rgb(from var(--primary-20) r g b / 0.1) 0%,
      rgb(from var(--primary-20) r g b / 1) 50%,
      rgb(from var(--primary-20) r g b / 0.1) 100%
    );
    mask-size: 100%;
    mask-position: center;
  }
}

@mixin bold-gradient-border {
  position: relative;
  border: 8px solid transparent;
  border-radius: 10px;
  background:
    linear-gradient(
        180deg,
        rgb(from var(--primary-95) r g b / 0.9) 0%,
        rgb(from var(--primary-95) r g b / 0.45) 100%
      )
      padding-box,
    linear-gradient(
        rgb(from var(--primary-95) r g b / 0.4),
        rgb(from var(--primary-95) r g b / 0.4)
      )
      border-box;

  &::before {
    position: absolute;
    inset: -9px;
    border-radius: 14px;
    border: 1px solid var(--primary-60);
    pointer-events: none;
    content: '';
  }
}
