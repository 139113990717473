
      @import 'styles-scss/abstracts/mixins';
    
// Add padding to body when header is sticky
body:has(.header) {
  // Adjust these values based on your header height
  padding-top: 114px; // Default for mobile

  @include break-reverse(1024) {
    padding-top: 128px; // For desktop
  }
}

// Remove body scrolling when mobile header is open
body:has(.header__mobile) {
  overflow: hidden;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding-block: var(--space-sm);
  font-family: var(--font-satoshi);
  font-size: var(--text-sm);
  font-weight: 400;
  line-height: 1.5;
  background: linear-gradient(
    to bottom,
    var(--primary-75) 0%,
    rgb(from var(--primary-100) r g b / 0.5),
    100%
  );
  box-shadow: 0 2px 4px rgb(from var(--neutral-100) r g b / 0.1);
  backdrop-filter: blur(10px);
  transition: padding-block var(--transition);

  &:not(.header__mobile) {
    .navigation {
      @include break(1023) {
        display: none;
      }
    }
  }

  @include break-reverse(1240) {
    font-size: var(--text-md);
  }
}

.header__sticky {
  position: fixed;

  @include break-reverse(1024) {
    animation: sticky-header-resize linear forwards;
    animation-timeline: scroll();
    animation-range: 0vh 10vh;
  }
}

@keyframes sticky-header-resize {
  from {
    padding-block: var(--space-sm);
  }

  to {
    padding-block: var(--space-xxs);
  }
}

.header__mobile {
  height: 100vh;

  &.header__sticky {
    overflow-y: auto;
  }
}

.wrapper {
  position: relative;
  z-index: 25;
  display: grid;
  grid-template-areas: 'logo actions' 'menu menu';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
  max-height: 100%;

  @include break-reverse(1024) {
    grid-template-areas: 'logo menu actions';
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr;
  }
}

.logoBlock {
  position: relative;
  z-index: 25;
  grid-area: logo;
  display: flex;
  align-items: center;

  .button__menu {
    margin-right: var(--space-xs);
    padding: 0;

    @include break-reverse(1024) {
      display: none;
    }
  }
}

.icon {
  stroke-width: 1.2;
}

.icon__menu,
.icon__close {
  width: var(--space-lg);
  height: var(--space-lg);
}

.icon__chevron {
  position: relative;
  top: 2px;
  order: 1;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  stroke: var(--neutral-40);
  transition: all var(--transition);
}

.icon__solutions {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  stroke-width: 1;
  stroke: var(--neutral-65);
  transition: all var(--transition);
}

.icon__rotated {
  transform: rotate(90deg);
}

.icon__arrow {
  flex-shrink: 0;
  align-self: flex-end;
  max-width: 20px;
  height: 20px;
  stroke: transparent;
  transition: stroke var(--transition);
}

.logo {
  @include break(1240) {
    display: none;
  }
}

.navigation {
  grid-area: menu;

  @include break(1023) {
    padding-block: var(--space);
    overflow-y: auto;
    max-height: 100%;
  }

  @include break-and-break-reverse(480, 1023) {
    padding-inline: var(--space-xs);
  }

  @include break-reverse(1024) {
    margin-inline: auto;
  }

  & .button__bookMobile {
    margin-top: var(--space);
    width: fit-content;
    font-size: var(--text-sm);

    @include break-reverse(490) {
      display: none;
    }
  }
}

.menuList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include break-reverse(1024) {
    flex-direction: row;
    align-items: center;
    gap: calc(var(--space-lg) * 0.5);
  }

  @include break-reverse(1240) {
    gap: var(--space);
  }
}

.menuItem {
  position: relative;
}

.menuItem__dropdown {
  &:hover {
    .menuTitle {
      color: var(--neutral-0);
    }

    .icon__chevron {
      stroke: currentColor;

      @include break-reverse(1024) {
        transform: rotate(180deg);
      }
    }

    .submenu {
      @include break-reverse(1024) {
        display: block;
      }
    }
  }

  &:has(.submenu__solutions) {
    position: static;
  }
}

.menuItem__open {
  .menuTitle {
    @include break(1023) {
      color: var(--neutral-0);
    }
  }

  .icon__chevron {
    @include break(1023) {
      transform: rotate(180deg);
      stroke: currentColor;
    }
  }

  .submenu {
    @include break(1023) {
      position: relative;
      display: block;
    }
  }
}

.menuTitle,
.menuLink {
  padding-block: var(--space-xs);
  font-size: var(--text-md);

  @include break-reverse(1024) {
    padding-block: var(--space-sm);
  }

  @include break-reverse(1240) {
    font-size: var(--text-lg);
  }
}

.menuTitle {
  display: flex;
  align-items: center;
  gap: var(--space-xxs);
  color: var(--neutral-40);
  transition: all var(--transition);
  cursor: pointer;
}

.menuLink {
  color: var(--neutral-40);

  &:before {
    content: '';
    position: absolute;
    inset: 0;
  }

  &:hover {
    span {
      color: var(--neutral-0);
    }
  }
}

.submenu {
  display: none;

  @include break-reverse(1024) {
    position: absolute;
    z-index: 30;
    top: 100%;
    left: 50%;
    transform: translate3d(0, 0, 0);
    padding-top: var(--space-xs);
  }

  &__center {
    @include break-reverse(1024) {
      transform: translate3d(-50%, 0, 0);
    }
  }

  &__left {
    @include break-reverse(1024) {
      transform: translate3d(-23%, 0, 0);
    }

    @include break-reverse(1240) {
      transform: translate3d(-30%, 0, 0);
    }

    @include break-reverse(1440) {
      transform: translate3d(-50%, 0, 0);
    }
  }
}

.submenu__solutions {
  @include break-reverse(1024) {
    width: calc(100% - var(--space-lg));
  }
}

.submenuWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  padding-block: var(--space-xs);

  @include break-reverse(1024) {
    flex-direction: row;
    justify-content: space-between;
    gap: calc(var(--space) * 0.25);
    padding: calc(var(--space) * 0.25);
    background: var(--primary-100);
    border: 1px solid var(--primary-40);
    border-radius: 16px;
    box-shadow: inset 0 0 15px 0 rgb(from var(--neutral-25) r g b / 0.1);
  }
}

.submenuWrapper__solutions {
  @include break-reverse(1024) {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(2, auto);
  }
}

.submenuBlock {
  display: flex;
  border-radius: 12px;
  backdrop-filter: blur(20px);
}

.submenuBlock__main {
  @include break(1023) {
    display: none;
  }

  @include break-reverse(1024) {
    flex-direction: column;
    justify-content: space-between;
    padding: var(--space-sm);
    grid-row: 1 / -1;
  }
}

.submenuBlock__info {
  flex-direction: column;
  gap: var(--space);
  order: 10;
  padding: var(--space-sm);
  background-image: linear-gradient(
    90deg,
    rgb(from var(--primary-60) r g b / 0.3),
    rgb(from var(--primary-60) r g b / 0.3) 100%
  );

  & .submenuBlockButton {
    font-size: var(--text-sm);

    @include break-reverse(490) {
      font-size: var(--text-md);
    }
  }

  @include break(1023) {
    max-width: 350px;
    box-shadow: inset 0 0 15px 0 rgb(from var(--neutral-25) r g b / 0.1);
  }

  @include break-reverse(1024) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    grid-column: 2 / -1;
    grid-row: 2 /-1;
  }
}

.submenuBlockVisual {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  & img {
    width: 49px;
    height: 105px;
  }
}

.submenuBlockCaption,
.submenuCaption {
  display: block;
  margin-bottom: var(--space-xs);
  font-size: var(--text-md);
  font-weight: 500;
}

.submenuBlockCaption {
  color: var(--neutral-0);
}

.submenuCaption {
  padding-inline: var(--space-xs);
  color: var(--neutral-65);

  @include break-reverse(1024) {
    padding: 0;
    color: var(--neutral-0);
  }
}

.submenuBlockDescription {
  display: block;
  line-height: 1.35;
  color: var(--neutral-40);
}

.submenuColumn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-xs);

  &:not(:last-child):after {
    @include break(1023) {
      content: '';
      display: block;
      background: var(--primary-60);
      height: 1px;
    }
  }

  @include break-reverse(1024) {
    padding: calc(var(--space-lg) * 0.25) var(--space-sm);
    border-radius: 12px;
    background-image: linear-gradient(
      90deg,
      rgb(from var(--primary-60) r g b / 0.3),
      rgb(from var(--primary-60) r g b / 0.3) 100%
    );
    backdrop-filter: blur(20px);
  }
}

.submenuColumn__solutions {
  & .icon__arrow {
    @include break-reverse(1024) {
      display: none;
    }
  }
}

.submenuColumn__solutions,
.submenuBlock__info {
  @include break-reverse(1024) {
    padding: var(--space-sm) calc(var(--space) - 2px);
  }

  @include break-reverse(1121) {
    padding: var(--space) calc(var(--space-md) - 4px);
  }
}

.submenuList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.submenuItem {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: calc(var(--space) * 0.25);
  border-radius: 8px;
  transition: background-color var(--transition);

  & .submenuLink {
    position: static;
  }

  &:has(.submenuLink:hover) {
    @include break(1023) {
      background-color: rgb(from var(--neutral-0) r g b / 0.05);
    }

    .icon__arrow,
    .icon__solutions {
      stroke: currentColor;
    }
  }

  @include break(1023) {
    padding: var(--space-xs);
  }

  @include break-reverse(1024) {
    text-wrap: nowrap;
  }
}

.submenuLink {
  display: block;
  width: 100%;
  color: var(--neutral-10);

  &:before {
    content: '';
    position: absolute;
    inset: 0;
  }

  span {
    justify-content: space-between;
    gap: var(--space-sm);

    @include break-reverse(480) {
      gap: var(--space);
    }
  }

  &:hover {
    span {
      color: var(--neutral-0);
    }
  }

  @include break-reverse(1024) {
    padding-block: var(--space-xxs);
  }
}

.actions {
  grid-area: actions;
  display: flex;
  align-items: center;
  column-gap: var(--space-xs);
  margin-left: auto;

  & .button__book,
  .button__sign,
  .submenuBlockButton {
    font-size: var(--text-sm);

    @include break-reverse(490) {
      font-size: var(--text-md);
    }
  }

  & .button__book {
    display: none;

    @include break-reverse(490) {
      display: block;
    }
  }
}
