
      @import 'styles-scss/abstracts/mixins';
    
.list {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: var(--space-xxs);
  column-gap: var(--space-xs);
  margin-bottom: var(--space);
  font-size: var(--text-sm);
  line-height: 1.4;
}

.item {
  display: inline-flex;
  align-items: center;
  gap: var(--space-xs);
  color: var(--neutral-30);
}

.link {
  color: var(--neutral-50);
  transition: color var(--transition);

  &:hover {
    color: var(--neutral-30);
  }
}

.icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  stroke-width: 1;
  stroke: var(--neutral-60);
}
