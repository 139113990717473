.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  font-size: inherit;
  white-space: nowrap;
  stroke: currentcolor;
  color: inherit;
}

.wrapper > img {
  margin-top: 0;
  margin-bottom: 0;
}

.wrapper .icon {
  margin: 0;
  width: 1.5rem;
  height: 1.5rem;
}
