/* ----- page styles ----- */
.page__section {
  padding-block: calc(var(--space-md) * 2);

  @include break-reverse(768) {
    padding-block: calc(var(--space-md) * 3);
  }
}

.page__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-xs);
  width: 100%;
}

.page__button {
  max-width: 335px;
  width: 100%;

  @include break-reverse(768) {
    width: fit-content;
  }
}

.page__background {
  z-index: 0;
  background: radial-gradient(
    ellipse,
    rgb(from var(--primary-20) r g b / 1) 0%,
    rgb(from var(--primary-20) r g b / 0) 100%
  );
  border-radius: 100%;
  filter: blur(40px);

  @include break-reverse(768) {
    filter: blur(50px);
  }

  @include break-reverse(1241) {
    filter: blur(60px);
  }
}

.page__glow {
  z-index: 0;
  background: var(--primary-20);
  border-radius: 100%;
  filter: blur(15px);
}
