
      @import 'styles-scss/abstracts/mixins';
    
.footer {
  position: relative;
  padding-block: var(--space-xl);
  background-color: var(--primary-100);
  border-top: 1px solid var(--primary-60);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-lg);
}

.content {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-areas: 'logo' 'navigation' 'categories';
  row-gap: var(--space-md);

  @include break-reverse(768) {
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'logo navigation' 'logo categories';
    column-gap: var(--space-md);
  }

  @include break-reverse(1024) {
    grid-template-areas: 'logo navigation categories';
    row-gap: initial;
  }
}

.logo {
  grid-area: logo;

  & img {
    align-self: start;
    min-width: 53px;
  }
}

.nav {
  grid-area: navigation;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space);

  @include break-reverse(768) {
    flex-wrap: nowrap;
  }
}

.categories {
  grid-area: categories;
}

.groupHead {
  font-size: var(--text-md);
  line-height: 1.25;
  color: var(--neutral-30);
  transition: color var(--transition);
}

.groupHeadIcon {
  order: 1;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  stroke-width: 1.2;
  stroke: var(--neutral-40);
  transition: all var(--transition);

  @include break-reverse(500) {
    display: none;
  }
}

.navList {
  display: flex;
  flex-flow: column wrap;
  margin-top: var(--space-xs);

  & .link {
    padding-block: var(--space-xxs);
  }
}

.link {
  font-size: var(--text-xs);
  white-space: nowrap;
  color: var(--neutral-40);
  border-bottom: 0.5px solid transparent;
  transition: border-color color var(--transition);

  @include break-reverse(768) {
    font-size: var(--text-sm);
  }
}

.link__active {
  color: var(--neutral-0);
  border-color: var(--neutral-40);
}

.link__icon {
  display: flex;
  align-items: center;
  gap: var(--space-xxs);
}

.link:hover:not(.link__active) {
  color: var(--neutral-0);
}

.categoriesList {
  display: flex;
  flex-flow: row wrap;
  gap: var(--space-xs);
  margin-top: calc(var(--space) * 0.5);
}

.categoriesLink {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-block: calc(var(--space-xxs) * 0.5);
  padding-inline: calc(var(--space) * 0.25);
  white-space: nowrap;
  border-radius: 25px;
  font-size: var(--text-sm);
  background-color: var(--primary-60);
  border: 1px solid var(--primary-60);
  transition: border-color var(--transition);

  &:hover {
    border-color: var(--neutral-80);
  }

  @include break-reverse(768) {
    padding-inline: calc(var(--space-lg) * 0.25);
    font-size: var(--text-md);
  }
}

.categoriesNumber {
  color: var(--neutral-40);
}

.credits {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space);
}

.creditsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-xs);
}

.creditsCopy {
  font-size: 12px;
  color: var(--neutral-65);

  & a {
    transition: color var(--transition);

    &:hover {
      color: var(--neutral-40);
    }
  }
}

.creditsLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-sm);

  @include break-and-break-reverse(576, 767) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 350px;
  }

  @include break-reverse(768) {
    flex-direction: row;
  }

  & .link {
    font-size: var(--text-md);

    @include break-reverse(768) {
      padding-block: calc(var(--space) * 0.25);
    }
  }

  & :has(.link:hover) .creditsLinksIcon {
    stroke: var(--neutral-0);
  }
}

.creditsLinksItem:not(:last-child) {
  position: relative;

  @include break-reverse(768) {
    display: flex;
    gap: var(--space-sm);

    &::after {
      content: '';
      align-self: stretch;
      flex-shrink: 0;
      width: 1px;
      height: auto;
      background-color: var(--primary-65);
    }
  }
}

.creditsLinksIcon {
  flex-shrink: 0;
  width: var(--space);
  height: var(--space);
  stroke-width: 1;
  stroke: var(--neutral-40);
  transition: all var(--transition);
}
