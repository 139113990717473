ul.tags {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
  color: inherit;
}

ul.tags li {
  padding: 0.5rem 1rem;
  font-size: 0.875rem; /* 14px */
  list-style: none;
  border-radius: 9999px;
  background-color: #272f55;
}

ul.tags li::before {
  content: none;
}

ul.tags li:not(:last-child) {
  margin-bottom: 0;
}
