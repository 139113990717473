
      @import 'styles-scss/abstracts/mixins';
    
.wrapper {
  margin: 0 auto;
  padding: 0 calc(var(--space-lg) * 0.5);
  max-width: 1240px;
}

.wrapper__grid {
  display: grid;
  gap: calc(var(--space-md) * 4);

  @include break-reverse(576) {
    gap: calc(var(--space-md) * 2);
  }

  @include break-reverse(768) {
    gap: calc(var(--space-md) * 3);
  }
}
