
      @import 'styles-scss/abstracts/mixins';
    
.logo {
  color: var(--neutral-0);
}

.image {
  width: 50px;
}

.text {
  margin-left: var(--space-xs);
  font-family: var(--font-logo);
  font-size: var(--text-lg);
  color: var(--neutral-0);
}
